<template>
    <div class="page">
        <div class="title">
            <img class="title__image" src="~@/assets/img/logo_red.svg" alt="">
        </div>
        <div class="menu">
            <div 
                class="menu__item" 
                v-for="article in filterResult"
                :key="article.id"
                @click="onSelectArticle(article)"
            >
                <img class="menu__image" :src="require(`@/assets/img/articles/png/${article.id}.png`)" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from "vuex";
import IdleVue from 'idle-vue';

const eventsHub = new Vue();
const options = {
  eventEmitter: eventsHub,
  idleTime: 60*5*1000,
  startAtIdle: false,
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'touchmove']
}
 
Vue.use(IdleVue, options);

export default {
  name: "Categories",
    components: {},
    computed: {
        ...mapGetters("ui", ["articles", "activeArticle"]),
        filterResult: function(){
            return this.articles.slice(0,12);
        }
      },
      onIdle() {
        this.$router.push('/');
      },
      data() {
        return {
            devMode:  process.env.NODE_ENV !== 'production',
        }
      },
      methods: {
        ...mapActions("ui", ["setActiveArticle"]),
        onSelectArticle(item) {
            this.setActiveArticle(item.id);
            this.$router.push('app');
        }
      },
}
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: column;
    }

    .title {
        margin: 6.25vh auto 4.25vh;
        width: 42.91vw;
        &__image {
            width: 100%;
            height: auto;
            margin-bottom: 1vh;
        }
    }
    .subtitle{
        font-family: 'Jost_Medium';
        font-size: 2.2vh;
    }

    .menu {
        display: flex;
        flex-wrap: wrap;
        width: 90vw;
        margin: 0 auto;
        justify-content: center;
        flex: 0;
        &__item {
            box-sizing: border-box;
            padding: 2vw 4.165vw;
            display: flex;
            flex: 0 1 32%;
            width: 32%;
            overflow: hidden;
            align-items: center;
            border: 2px solid transparent;

            @media (hover: none) and (pointer: coarse) {
                &:active{
                    box-sizing: border-box;
                    border-radius: 2.5vw;
                    border: 2px solid #666;
                    text-decoration: none;
                }
            }
        }
        &__image {
            width: 100%;
            height: auto;
            pointer-events: none;
        }
    }

    img {
        -webkit-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
        -webkit-app-region: no-drag;
    }
</style>